import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Bar,
  mixins: [reactiveProp],
  // props: ['options'],
  props: {
    chartdata: {
      type: Object,
      default: null
    },
  },
  mounted () {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    // this.renderChart(this.chartData, this.options)
  },
  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            data;
            const label = data.datasets[tooltipItem.datasetIndex].label;
            const value = tooltipItem.yLabel;
            // console.log(data.datasets[tooltipItem.datasetIndex].label);
            // label;
            // console.log(data);
            // const qte = data.datasets[tooltipItem.datasetIndex].quantite_commande[tooltipItem.index]+'/'+data.datasets[tooltipItem.datasetIndex].quantite_total[tooltipItem.index];
            // return label+`: ${value}`;
            return label + ": " + (new Intl.NumberFormat().format( value));
          }
        }
      }, 
      scales: {
        yAxes:[{
          ticks: {
            min: 0,
            callback: (value) => {
                  return (new Intl.NumberFormat().format( value));
            },
          },
        }]
      },
    }
  })
}